$black: #1d1d1d;
$gray1: #3d3d3d;
$gray2: #5d5d5d;
$gray3: #7d7d7d;
$gray4: #9d9d9d;
$gray5: #bdbdbd;
$white: #d1d1d1;

@import 'App';
@import 'styles/navbar';
@import 'styles/cardBox';
