.cardBox {
  body {
    margin: 0;
    font-family: Helvetica, sans-serif;
    background: #1f1f1f;
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  * {
    box-sizing: border-box;
  }

  p,
  small {
    color: #8f8f8f;
  }

  p {
    font-size: 0.9em;
    line-height: 23px;
  }

  h1 {
    margin: 0;
  }

  .skewed {
    width: 45em;
    color: #f1f1f1;
    display: flex;
    background: #111;
    margin: 2em 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
  }

  .text {
    padding: 1.5em;
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  .image {
    width: 50%;
    height: 17em;
    position: relative;
    overflow: hidden;
  }

  .image::before {
    content: '';
    position: absolute;
    left: -6.1em;
    top: 50%;
    transform: translateY(-50%) rotate(20deg);
    background: #111;
    width: 40%;
    height: 140%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  }

  .skewed-left .image::before {
    transform: translateY(-50%) rotate(-20deg);
    left: initial;
    right: -6.1em;
  }

  img {
    width: 140%;
  }

  @media screen and (max-width: 750px) {
    .skewed {
      display: block;
      width: 20em;
    }
    .image,
    .text {
      width: 100%;
    }
    img {
      width: 160%;
    }
    .skewed .image::before {
      width: 120%;
      height: 40%;
      top: -5em;
      left: 50%;
      transform: translateX(-50%) rotate(10deg);
    }
    .skewed-left .image::before {
      transform: translateX(-50%) rotate(-10deg);
      top: initial;
      bottom: -5em;
    }
  }
}
