body {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #1d1d1d;
  color: #d1d1d1;
}

.articles_feed {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90vw;
  margin: 0 auto;
  margin-top: 12.5vh;
}
